const menuButton = document.getElementById('menu-button');
const navWrap = document.querySelector('.sauce-navigation__wrap');
const body = document.querySelector('body');
console.log(menuButton);

function slideOutNav() {
  menuButton.addEventListener('click', (e) => {
    e.preventDefault();
    menuButton.classList.toggle('menu-button--is-active');
    navWrap.classList.toggle('is-open');
    body.classList.toggle('no-scroll');
  });

  function closeOnResize() {
    if (window.innerWidth > 900) {
      navWrap.classList.remove('is-open');
    }
  }

  window.onresize = closeOnResize;
}

export default slideOutNav;

function scrollDetect() {
  var lastScroll = 0;

  window.onscroll = function () {
    let currentScroll = document.documentElement.scrollTop || document.body.scrollTop;

    if (currentScroll > 0 && lastScroll <= currentScroll) {
      lastScroll = currentScroll;
      document.body.classList.add('down');
      document.body.classList.remove('up');
    } else {
      lastScroll = currentScroll;
      document.body.classList.add('up');
      document.body.classList.remove('down');
    }
  };
}

export default scrollDetect;

function navOnScroll() {
  const nav = document.querySelector('.header__fixed');
  let heightOfNav = nav.offsetHeight * 1.5;

  function pinNav() {
    if (document.body.classList.contains('up')) {
      nav.classList.add('pin');
      nav.classList.remove('unpin');
    } else if (window.scrollY >= heightOfNav) {
      nav.classList.remove('pin');
      nav.classList.add('unpin');
    }
  }

  window.addEventListener('scroll', pinNav);
}

export default navOnScroll;

import AOS from 'aos';
import ModalVideo from 'modal-video';
import scrollDetect from './utilities/scrolldetect';
import navOnScroll from './components/navOnScroll';
import slideOutNav from './components/slideOutNav';

// window.addEventListener('load', () => {
//   new ModalVideo('.js-modal-btn', { channel: 'vimeo' });
// });

scrollDetect();
navOnScroll();
slideOutNav();

(function () {
  window.addEventListener('load', () => {
    var buttonForVideo = document.querySelector('.js-modal-btn');

    if (buttonForVideo) {
      new ModalVideo('.js-modal-btn', { channel: 'vimeo' });
    }
  });
})();

console.log('start');
